:root {
  --desktopBg: #018281;
  --primaryBg: #c0c0c0;
  --secondaryBg: #808080;
  --hoverBlue: #00007c;
  --app-height: 100vh;
}
* {
  box-sizing: border-box;
  scrollbar-color: var(--primaryBg) lightgray;
}
::-webkit-scrollbar {
  width: 20px;
  height: 0;
}
::-webkit-scrollbar-thumb {
  background: var(--primaryBg);
  border: 2px outset lightgray;
}
::-webkit-scrollbar-track {
  background: lightgray;
}
p {
  padding: 0;
}

html {
  height: 100%;
  overflow-x: hidden;
}

body {
  overflow: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "default", Times, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: inherit;
}
.homeScreen {
  overflow: hidden;
  position: fixed;
  display: flex;
  background-color: var(--desktopBg);
  height: var(--app-height);
  max-height: var(--app-height);
  width: 100vw;
}

.desktop {
  color: white;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100vw;
  height: 95vh;
}

.startBar {
  background-color: var(--primaryBg);
  border-top: 2px solid whitesmoke;
  display: flex;
  justify-content: space-between;
  width: 100vw;
  height: 50px;
  position: absolute;
  bottom: 0;
  z-index: 50;
}
.startBtn {
  width: 100px !important;
  background-color: var(--primaryBg);
  border: 2px black outset;
  border-top-color: white;
  border-left-color: white;
  font-weight: bold;
  font-size: 1.5rem;
  border-radius: 0;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}
.btnContentWrapper {
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.notPressed {
  overflow-x: hidden;
  min-width: 50px;
  white-space: nowrap;
  display: flex;
  font-size: 1.5rem;
  justify-content: start;
  align-items: center;
  padding: 0.25rem;
  border: 2px outset black;
  border-top-color: white;
  border-left-color: white;
  width: 170px;
  margin: 0.25rem;
}
.notPressed img {
  padding-right: 0.5rem;
}

.pressed {
  overflow-x: hidden;
  white-space: nowrap;
  display: flex;
  font-size: 1.5rem;
  justify-content: start;
  align-items: center;
  padding: 0.25rem;
  background-color: lightgray;
  margin: 0.25rem;
  border: 2px inset;
  min-width: 80px;
  width: 170px;
  border-bottom-color: white;
  border-right-color: white;
}
.pressed img {
  padding-right: 0.5rem;
}
.pressed .btnContentWrapper {
  border: 2px dotted;
  height: 80%;
}
.startBtnIcon {
  height: 80%;
}
.clock {
  display: flex;
  min-width: fit-content;
  justify-content: center;
  margin: 5px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  align-items: center;
  border: 2px inset;
  border-bottom-color: whitesmoke;
  border-right-color: whitesmoke;
}

.startMenu {
  display: flex;
  z-index: 52;
  align-items: flex-end;
  margin-left: 5px;
  position: relative;
  align-self: flex-end;
  height: fit-content;
  width: fit-content;
  padding-right: 0.25rem;
  bottom: 50px;
  background-color: var(--primaryBg);
  border: 2px outset black;
  border-left-color: whitesmoke;
  border-top-color: whitesmoke;
}
.startMenuText {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  margin: 0;
  left: 0;
  text-align: left;
  writing-mode: vertical-rl;
  height: 100%;
  width: 2.5rem;
  background-color: var(--secondaryBg);
}
.startMenuText h1 {
  margin: 0;
  margin-top: 2rem;
  font-stretch: ultra-expanded;
  font-weight: bolder;
}
.programs {
  width: calc(100% - 2.5rem);
  margin-left: 2.5rem;
}
.program {
  padding: 0.25rem;
  padding-right: 4rem;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
}
.program:hover {
  background-color: var(--hoverBlue);
  color: white;
}
.program img {
  min-height: 50px;
  min-width: 50px;
  padding-right: 1rem;
}
.shutdown {
  border-top: 2px whitesmoke outset;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
}
.shutdown:hover {
  background-color: var(--hoverBlue);
  color: white;
}
.shutdown img {
  padding-right: 1rem;
  height: 50px;
  width: 50px;
}
.icon {
  color: inherit;
  height: fit-content;
  margin: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80px;
}
.icon p {
  margin: 0;
}
.icon img {
  padding-bottom: 0.25rem;
  width: 70%;
}
.Bio {
  padding: 0;
  margin: 0;
  touch-action: none;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--primaryBg);
  height: auto;
  max-height: 500px;
  width: auto;
  max-width: 400px;
  position: absolute;
  border: 2px outset black;
  border-top-color: whitesmoke;
  border-left-color: whitesmoke;
  top: 25%;
  left: 25%;
}
.windowNav {
  width: 100%;
  font-size: 20px;
  margin: 2px;
  display: flex;
  justify-content: space-between;
  height: 1.75rem;
  max-width: 100%;
  background-color: var(--secondaryBg);
  color: white;
}
.windowNav p {
  margin: 0;
  padding-left: 0.5rem;
  font-weight: bold;
}
.nameAndIcon {
  margin-left: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.windowNavBtns {
  display: flex;
  align-items: center;
  margin-right: 2px;
}
.navBtn {
  padding: 0;
  margin: 0;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;
  height: 18px;
  width: 18px;
  background-color: var(--primaryBg);
  border-radius: 0;
  color: black;
}
.bioMain {
  text-align: center;
  max-width: 800px;
  min-width: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}
.bioMain p {
  padding: 1rem;
}
.bioMain h1 {
  padding-bottom: 0.5rem;
  font-size: 30px;
}
.selfie {
  height: 200px;
  max-width: 100%;
  border-radius: 350%;
}
.logos {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  height: fit-content;
}
.techLogo {
  padding: 0;
  height: 50px;
}
.bioTitle {
  margin: 1rem 0 0.5rem 0;
}
.Projects {
  touch-action: none;
  overflow-y: none;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--primaryBg);
  height: fit-content;
  height: 400px;
  width: 40vw;
  min-width: 250px;
  max-width: 400px;
  position: absolute;
  border: 2px outset black;
  border-top-color: whitesmoke;
  border-left-color: whitesmoke;
  top: 25%;
  left: 25%;
}
.top {
  position: absolute;
  z-index: 100;
}
.top nav {
  background-color: var(--hoverBlue);
}
.projectsBody {
  display: flex;
  flex-wrap: wrap;
  color: black;
  position: relative;
  width: 95%;
  background-color: white;
  margin: 1rem;
  border: 2px inset black;
  min-height: 80%;
  overflow: auto;
}
.npsProject {
  overflow-y: hidden;
  overflow-x: hidden;
  touch-action: none;
  border: 2px outset black;
  border-top-color: whitesmoke;
  border-left-color: whitesmoke;
  position: absolute;
  top: 25%;
  left: 60%;
  background-color: var(--primaryBg);
  height: fit-content;
  min-width: 300px;
  height: 400px;

  max-height: 100vh;
  width: 30vw;
}
.screenShot {
  width: 70%;
}
.icons {
  padding-bottom: 2rem;
  margin: 0 auto;
  display: flex;
  width: 40%;
  justify-content: space-between;
}
.fullScreen {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  max-width: none !important;
  max-height: none !important ;
  font-size: 1.35rem;
  height: 100vh !important;
  width: 100vw !important;
}

.techText {
  padding: 0;
  max-width: 100%;
  width: 100%;
  min-width: 250px;
  text-align: center;
  display: flex;
  justify-content: center;
}
.openWindows {
  margin-left: 0;
  margin-right: auto;
  display: flex;
  /* border: 2px solid red; */
  width: 100%;
  overflow-x: auto;
}
.openWindowIcon {
  overflow-x: hidden;
  width: 1fr;
  min-width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 150px;
  flex-grow: 3;
  margin: 0.25rem;
}

.selected {
  width: 1fr;
  min-width: 50px;
  border: 2px inset red;
}
.projectBody {
  overflow-y: auto;
  height: 90%;
  max-width: 1200px;
  padding: 1rem;
  padding-bottom: 0;
  margin: 0 auto;
}

@media screen and (max-width: 400px) {
  .window {
    left: 10%;
  }
  .npsProject {
    left: 5%;
  }
}

/* minesweeper styles */

.gameBoard {
  touch-action: none;
  position: relative;
  box-sizing: content-box;
  border: 2px outset black;
  border-top-color: whitesmoke;
  border-left-color: whitesmoke;
  border-bottom: none;
  height: fit-content;
  width: calc(30px * 10);
  max-width: calc(30px * 10);
  display: flex;
  flex-wrap: wrap;
}
.gameWrapper {
  width: calc(30px * 10);
  max-width: calc(30px * 10);
  height: fit-content;
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: var(--secondaryBg);
}

.tile {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  background-color: var(--primaryBg);
  border: 4px solid black;
  border-top-color: white;
  border-left-color: white;
  color: transparent;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.visibleBomb {
  height: 30px;
  width: 30px;
  border: 1px solid gray;
  background-color: var(--primaryBg);
  background-image: url(../src/assets/bomb.png);
  background-position: center;
  background-size: contain;
}
.visible {
  background-color: var(--primaryBg);
  border: 1px solid gray;
  color: black !important;
}
.gameover {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  flex-direction: column;
  padding-top: 30%;
  align-items: center;
  text-align: center;
}
.flag {
  background-image: url(../src/assets/flag.png);
  background-position: center;
  background-size: contain;
  background-repeat: none;
}
.scoreBoard {
  box-sizing: border-box;
  border: 2px solid var(--secondaryBg);
  position: relative;
  height: 100px;
  background-color: white;
  width: 100%;
}
